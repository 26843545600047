export default [
  {
    header: 'Cài đặt',
    resource: 'setting',
    action: 'read',
  },
  {
    title: 'Hình thức thanh toán',
    icon: 'CreditCardIcon',
    route: 'tingees',
    resource: 'setting',
    action: 'read',
  },
  {
    title: 'Mẫu thông báo phí',
    icon: 'SlidersIcon',
    route: 'templates',
    resource: 'setting',
    action: 'read',
  },
  {
    title: 'Cài đặt khác',
    icon: 'SlidersIcon',
    route: 'setting',
    resource: 'setting',
    action: 'read',
  },

];
